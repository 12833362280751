<template>
  <div class="flex">
    <VueDatePicker v-model="date" model-type="yyyy-MM-dd" range @cleared="cleared"
                   placeholder="Выберите диапазон"></VueDatePicker>
  </div>
  <div v-if="usersWhiteList">

  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "../Technical/Preloader.vue";
import ShowCountTest from "./CellRenderers/ShowCountTest.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "staff-table",
  components: {
    Preloader,
    ShowCountTest,
    VueDatePicker
  },
  setup() {
    const store = useStore();
    const countOnPage = ref(15),
        pageDataStart = ref(0),
        isPreloader = ref(false);
    const date = ref();
    const dateFrom = new Date();
    const dateTo = new Date(new Date().setDate(dateFrom.getDate() - 7));
    date.value = [{date_from: dateFrom, date_to: dateTo}];

    const usersWhiteList = computed(() => store.state.users.usersWhiteList),
        rowData = computed(() => {
          return usersWhiteList.value.map((user) => {
            return {
              id: user.id,
              name: user.name,
              full: {
                full_active_count: user.full_active_count,
                full_free_count: user.full_free_count,
                full_issued_count: user.full_issued_count,
                full_total: user.full_total
              },
              standard: {
                standard_active_count: user.standard_active_count,
                standard_free_count: user.standard_free_count,
                standard_issued_count: user.standard_issued_count,
                standard_total: user.standard_total,
              }
            };
          });
        });

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "name",
          headerName: "Название",
          wrapText: true,
          minWidth: 200,
        },
        {
          field: "Report",
          headerName: "Oтчет",
          wrapText: true,
          minWidth: 400,
          cellRenderer: "ShowCountTest",
          cellRendererParams: {
            test: "test",
          },
        },
        {
          field: "active_count",
          headerName: "Проходят тесты",
          wrapText: true,
          minWidth: 150,
          cellRenderer: "ShowCountTest",
          cellRendererParams: {
            active: "active",
          },
        },
        {
          field: "free_count",
          headerName: "Неначатых тестов",
          wrapText: true,
          minWidth: 150,
          cellRenderer: "ShowCountTest",
          cellRendererParams: {
            free: "free",
          },
        },
        {
          field: "issued_count",
          headerName: "Пройдено тестов",
          wrapText: true,
          minWidth: 150,
          cellRenderer: "ShowCountTest",
          cellRendererParams: {
            issued: "issued",
          },
        },
        {
          field: "total",
          headerName: "Всего тестов",
          wrapText: true,
          minWidth: 150,
          cellRenderer: "ShowCountTest",
          cellRendererParams: {
            total: "total",
          },
        },
      ],
    });

    const getUsers = () => {
      store.dispatch("users/getUsersWhiteList");
    }

    onBeforeMount(() => {
      getUsers()
    });

    const getUsersWhiteListBySearch = (date) => {
      store
          .dispatch("users/getUsersWhiteListBySearch", date.value)
    };

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    watch(date, () => {
      if (date.value !== null) {
        getUsersWhiteListBySearch(date)
      }
    });

    const cleared = () => {
      getUsers()
    }

    return {
      countOnPage,
      isPreloader,
      changePage,
      usersWhiteList,
      columnDefs,
      rowData,
      date,
      cleared,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
