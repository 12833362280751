<template>
  <div class="filters">
    <div class="filter_block">
      <custom-input
          :delay="400"
          name="curator-passings-sort"
          label="Имя"
          :inputWidth="100"
          cleanable
          v-model="searchParams.nameField"
      />
      <custom-input
          :delay="400"
          name="curator-passings-sort"
          label="Email"
          :inputWidth="100"
          cleanable
          v-model="searchParams.emailField"
      />
    </div>
    <div class="search_btn search__clear-btn">
      <button @click="clearAllSearch" type="button" class="btn btn-w btn-sm">
        Очистить
      </button>
    </div>
  </div>
  <div v-if="curators">
    <div v-if="shownData.length">
      <base-table
          v-if="shownData.length"
          :columns="curatorFields"
          :rows="shownData"
      />
      <table-pagination
          v-if="shownData.length"
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import {useRoute} from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import sortData from "@/mixins/sortData";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
import UsersWhiteListTable from "@/components/Tables/UsersWhiteListTable.vue";

export default {
  name: "curator-table",
  components: {
    UsersWhiteListTable,
    BaseTable, TablePagination,
    Preloader,
    CustomInput,
  },
  setup() {
    const store = useStore(),
        route = useRoute();

    const countOnPage = ref(15),
        page = ref(1),
        isPreloader = ref(false),
        searchParams = reactive({
          nameField: "",
          emailField: "",
        });
    const curators = computed(() => store.state.curator.curators),
        rowData = computed(() => {
          return curators.value.map((curator) => {
            return {
              id: curator.id,
              name: curator.name,
              email: curator.email,
              photo: curator.image,
              groups: curator.groups,
              passing_count: curator.passing_count,
              edit: curator.id,
            };
          });
        });

    const curatorFields = [
      {
        field: "photo",
        headerName: "Фото",
      },
      {
        field: "name",
        headerName: "Имя",
      },
      {
        field: "email",
        headerName: "Email",
      },
      {
        field: "groups",
        headerName: "Группы",
      },
      {
        field: "passing_count",
        headerName: "",
      },
      {
        field: "edit",
        headerName: "",
        dest: "staff",
        res: "edit",
      },
    ];

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref();

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.nameField && searchParams.nameField.length >= 3) {
          data = data.filter((el) => el.name.toLowerCase().includes(searchParams.nameField.toLowerCase()));
        }

        if (searchParams.emailField && searchParams.emailField.length >= 3) {
          data = data.filter((el) => el.email.toLowerCase().includes(searchParams.emailField.toLowerCase()));
        }

        data = sortData(data, 'name', 'up');

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const clearAllSearch = () => {
      searchParams.nameField = "";
      searchParams.emailField = "";
    };

    onBeforeMount(() => {
      store.dispatch("curator/getCurators");
    });

    watch(
        () => [searchParams.nameField, searchParams.emailField],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      route,
      countOnPage,
      isPreloader,
      page,
      changePage,
      curators,
      searchParams,


      rowData,
      shownData,
      clearAllSearch,
      curatorFields,
      updatePagination,
      totalResults,

    };
  },
};
</script>

<style lang="scss" scoped>
.no_results {
  padding: 40px 10px;
}

.filters {
  display: flex;
  row-gap: 5px;
  flex-direction: column;

}

.filter_block {
  display: flex;
  column-gap: 5px;
}

.filter_block .form-group {
  width: 50%;
}

.search_btn {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .filter_block {
    flex-direction: column;
    width: 100%;
  }

  .filter_block .form-group {
    width: 100%;
  }
}
</style>